<template>
  <v-container class="bg-glass-effect-lighter px-3" fluid>
    <backArrow style="margin-left: 12px; margin-top: 5px; position: initial;"/>

    <v-dialog v-model="dialog.show" persistent>
      <v-card :style="dialog.style" rounded="lg">
        <v-row justify="center" class="ma-0 px-0 pt-4" style="height: 100%;">
          <v-col cols="11" align-self="start">
            <v-row>
              <v-col col="12">
                <h5 class="pb-5 generic-title" style="text-align: center; font-weight: bold;" :style="{color: dialog.colorDefault}"> {{ dialog.title }} </h5>
                <p class="px-5 text-dialog">{{ dialog.message.text }}</p>
                <p class="px-5 text-dialog"> {{ dialog.message.textLink }} <a target="_blank" :href="dialog.message.link">clique aqui</a>.</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="11" align-self="center">
            <v-btn class="btn btn-great mb-2" :color="dialog.colorDefault" block x-large rounded @click="onClickAccept()">Aceitar</v-btn>
            <v-btn class="btn btn-great btn-negative" block x-large rounded @click="onClickNotAccept()">Recusar</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" class="ma-0 pa-0">
        <v-stepper class="stepper-layout" v-model="stepCount">
          <v-stepper-items>
            <v-stepper-content class="py-0 my-0" step="0">
              <v-row class="py-0 my-0">
                <!--start-->
                <v-col class="py-0 my-0" align-self="start" cols="12">
                  <v-row class="py-0 pt-5 my-0">
                    <v-col cols="12" align="center">
                      <v-avatar color="white">
                        <v-icon color="primary">fas fa-video</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" align="center">
                      <h1>{{ title }}</h1>
                      <h4>{{ subtitle }}</h4>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="py-0 my-0" cols="12">
                  <v-sheet class="mt-5 mb-5" color="primary" rounded="lg">
                    <p class="pa-2" style="color: white">
                      <span style="font-weight: bold;"><v-icon class="pr-1" color="white" small>fas fa-exclamation-triangle</v-icon> Atenção</span> - Informe seus dados completos antes de iniciar a chamada de vídeo.
                    </p>
                  </v-sheet>
                </v-col>

                <v-col cols="12">
                  <v-form ref="formAttendeeData" v-model="isValidFormAttendeeData" lazy-validation>
                    <v-card rounded="lg">
                      <v-card-text>

                        <v-row class="mb-5">
                          <v-col cols="12">
                            <v-icon color="primary">fas fa-id-badge</v-icon>
                            <span class="ml-2 topic font-weight-bold" style="color: var(--v-primary)">Dados Pessoais</span>
                          </v-col>

                          <v-col cols="12" class="pt-1">
                            <v-row>
                              <v-col cols="12">
                                <span class="pt-1 topic">Usuário atendido</span>
                              </v-col>
                            </v-row>
                            <v-autocomplete
                                :items="attendees"
                                v-model="attendeeSelected"
                                item-text="name"
                                return-object
                                placeholder="Selecione o Atendido"
                                color="primary">
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" class="pt-1 pb-6" v-if="attendeeSelected">
                            <v-row>
                              <v-col cols="12">
                                <span style="font-size: 12px;">CPF</span>
                              </v-col>
                            </v-row>
                            <span class="text--primary" style="font-size: 16px;">{{attendeeSelected.cpf ? attendeeSelected.cpf : '-'}}</span>
                          </v-col>

                          <v-col cols="12" class="pt-1 pb-6" v-if="attendeeSelected">
                            <v-row>
                              <v-col cols="12">
                                <span style="font-size: 12px;">Data de Nascimento</span>
                              </v-col>
                            </v-row>
                            <span class="text--primary" style="font-size: 16px;">{{attendeeSelected.birthDate ? attendeeSelected.birthDate : '-'}}</span>
                          </v-col>

                          <v-col cols="12" class="py-1" v-if="attendeeSelected">
                            <v-text-field label="E-mail" type="email" v-model="attendeeSelected.email" :rules="[rules.email, rules.requiredText]"></v-text-field>
                          </v-col>

                          <v-col cols="12" class="py-1" v-if="attendeeSelected">
                            <v-text-field label="Celular" v-model="attendeeSelected.phone" type="tel" v-mask="$util.formmasks.dddTel" :rules="[rules.requiredText]"></v-text-field>
                          </v-col>

                          <v-col cols="12" class="py-1" v-if="attendeeSelected">
                            <v-select label="Sexo Biológico" v-model="attendeeSelected.biologicGender" :items="biologicGenders" item-value="code" item-text="description" color="primary" :rules="[rules.requiredArray]"></v-select>
                          </v-col>

                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-form>
                </v-col>

                <!--end-->
                <v-col align-self="end" cols="12">
                  <v-row>
                    <v-col cols="12">
                      <v-btn class="py-7 mb-4 btn btn-great mt-3" block rounded large color="primary" @click="onClickFirstStep()" :disabled="!attendeeSelected">Próximo</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content class="py-0 my-0" step="1">
              <v-row class="py-0 my-0">
                <!--start-->
                <v-col class="py-0 my-0" align-self="start" cols="12">
                  <v-row class="py-0 pt-5 my-0">
                    <v-col cols="12" align="center">
                      <v-avatar color="white">
                        <v-icon color="primary">fas fa-video</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" align="center">
                      <h1>{{ title }}</h1>
                      <h4>{{ subtitle }}</h4>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="py-0 my-0" cols="12">
                  <v-sheet class="mt-5 mb-5" color="primary" rounded="lg">
                    <p class="pa-2" style="color: white">
                      <span style="font-weight: bold;"><v-icon class="pr-1" color="white" small>fas fa-exclamation-triangle</v-icon> Atenção</span> - Informe seus dados completos antes de iniciar a chamada de vídeo.
                    </p>
                  </v-sheet>
                </v-col>

                <v-col cols="12">
                  <v-card rounded="lg">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-icon color="primary">fas fa-map-marker-alt</v-icon>
                          <span class="ml-2 topic font-weight-bold" style="color: var(--v-primary)">Busca de Endereço</span>
                        </v-col>

                        <v-col cols="12" class="pt-0 mt-0">
                          <google-map-autocomplete cols="12" @input="upateAddress" placeholder="Digite o endereço completo"/>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" v-if="showDetailFields">
                  <v-form ref="formAddressData" v-model="isValidFormAddressData" lazy-validation>
                    <v-card rounded="lg">
                      <v-card-text>

                        <v-row class="mb-5">
                          <v-col cols="12">
                            <v-icon color="primary">fas fa-address-card</v-icon>
                            <span class="ml-2 topic font-weight-bold" style="color: var(--v-primary)">Dados de Endereço</span>
                          </v-col>

                          <v-col cols="12" class="py-1">
                            <v-text-field label="Endereço" v-model="local.address" :rules="[rules.requiredText]" readonly></v-text-field>
                          </v-col>

                          <v-col cols="12" class="py-1">
                            <v-text-field label="Número" v-model="local.number" :rules="[rules.requiredText, rules.limitNumber]" maxlength="10"></v-text-field>
                          </v-col>

                          <v-col cols="12" class="py-1">
                            <v-text-field label="Bairro" v-model="local.neighborhood" :rules="[rules.requiredText]" readonly></v-text-field>
                          </v-col>

                          <v-col cols="12" class="py-1">
                            <v-text-field label="CEP" v-model="local.cep" v-mask="'#####-###'" :rules="[rules.validCEP]"></v-text-field>
                          </v-col>

                          <v-col cols="12" class="py-1">
                            <v-text-field label="Cidade" v-model="local.city" :rules="[rules.requiredText]" readonly></v-text-field>
                          </v-col>

                          <v-col cols="12" class="py-1">
                            <v-text-field label="Estado" v-model="local.state" :rules="[rules.requiredText]" readonly></v-text-field>
                          </v-col>

                          <v-col cols="12" class="py-1">
                            <v-text-field label="País" v-model="local.country" :rules="[rules.requiredText]" readonly></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-form>
                </v-col>

                <!--end-->
                <v-col align-self="end" cols="12">
                  <v-row>
                    <v-col cols="12">
                      <v-btn class="py-7 mb-4 btn btn-great mt-3" block rounded large color="primary" @click="onClickSecondStep()" :disabled="isDisabledAddress">Próximo</v-btn>
                      <v-btn class="py-7 mb-4 btn btn-great" block rounded large @click="stepCount--">Voltar</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content class="py-0 my-0" step="2">
              <v-row class="py-0 my-0">
                <!--start-->
                <v-col class="py-0 my-0" align-self="start" cols="12">
                  <v-row class="py-0 pt-5 my-0">
                    <v-col cols="12" align="center">
                      <v-avatar color="white">
                        <v-icon color="primary">fas fa-video</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" align="center">
                      <h1>{{ title }}</h1>
                      <h4>{{ subtitle }}</h4>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="py-0 my-0" cols="12">
                  <v-sheet class="mt-5 mb-5" color="primary" rounded="lg">
                    <p class="pa-2" style="color: white">
                      <v-icon class="pr-1" color="white" small>fas fa-info-circle</v-icon> As informações foram salvas com sucesso. Clique no botão abaixo para iniciar a chamada de vídeo.
                    </p>
                  </v-sheet>
                </v-col>

                <v-col cols="12">

                </v-col>

                <!--end-->
                <v-col align-self="end" cols="12" class="py-0 my-0">
                  <v-row>
                    <v-col cols="12">
                      <v-btn class="py-7 mb-4 btn btn-great" block rounded large color="primary" @click="onClickThirdStep()">
                        <v-icon class="mr-2" color="white">fas fa-video</v-icon>
                        Iniciar Chamada
                      </v-btn>
                      <v-btn class="py-7 mb-4 btn btn-great" block rounded large @click="$router.back()">Finalizar</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import backArrow from '../../components/covid/back-arrow';
import {mapMutations} from "vuex";
import {regexEmail} from "@/common/constants";
import GoogleMapAutocomplete from "../../components/google-maps/google-map-autocomplete";
import moment from "moment/moment";
import GenderService from "@/services/contract/GenderService";
import StateService from '@/services/contract/StateService';
import AddressService from '@/services/contract/AddressService';
import MedicalAppointmentEventService from '@/services/healthcare/MedicalAppointmentEvent';
import TelemedicineService from "@/services/euprotegido/TelemedicineService";


export default {
  name: "telemedicineDuty",
  components: { backArrow, GoogleMapAutocomplete },
  data: () => ({
    attendees: [],
    attendeeSelected: null,
    countryCode: 'BR',
    dialog: {
      message: {
        text: 'Para prosseguir você deve aceitar os termos e condições.',
        textLink: 'Para ler os termos,',
        link: '#',
      },
      title: 'Termos e Condições',
      show: true,
      colorDefault: 'primary',
      style: {
        color: '#532E88',
        backgroundColor: '#EEEDF7',
      }
    },
    biologicGenders: [],
    isValidFormAttendeeData: true,
    isValidFormAddressData: true,
    link: '#',
    local: {
      cep: null,
      neighborhood: null,
      address: null,
      number: null,
      city: null,
      state: null,
      country: null,
    },
    localTemp: null,
    stepCount: 0,
    telemedicineDuty: null,
    title: 'Telemedicina',
    subtitle: 'Plantão 24 Horas',
    showDetailFields: false,
    rules: {
      limitNumber: value => value && value.length <= 10 || 'Máximo 10 caracteres',
      validCEP: (value) => value && value.length === 9 || 'CEP inválido',
      requiredText: (value) => !!value || 'Campo obrigatório',
      requiredArray: (value) => (value && value.length > 0) || 'Campo obrigatório',
      email: (value) => {
        if (value && value.length > 0) {
          const pattern = regexEmail;
          return pattern.test(value) || 'E-mail inválido';
        }
        return true;
      }
    }
  }),
  computed: {
    isDisabledAddress() {
      return !this.isValidFormAddressData || !this.local || (!this.local.cep || !this.local.city || !this.local.address || !this.local.state
          || !this.local.country || !this.local.neighborhood || !this.local.number)
    }
  },
  methods: {
    ...mapMutations(['loading', 'showmsg']),
    async loadURLTerms() {
      await this.appointmentEventService.getURLFromTelemedicineDutyTerms()
          .then((result) => {
            if(result && result.data) {
              this.dialog.message.link = result.data;
            }
          })
          .catch(() => {
            this.showmsg({text: 'Ocorreu um erro ao tentar carregar os Termos e Condições de Uso.', type: 'error'});
            this.loading(false);
          });
    },
    async loadGenders() {
      await this.genderService.FindAll().then((response) => {
        if (response && response.data) {
          this.biologicGenders = response.data;
        }
      })
      .catch(() => {
        this.showmsg({text: 'Ocorreu um erro ao tentar carregar os gêneros.', type: 'error'});
        this.loading(false);
      });
    },
    async loadStates() {
      this.stateService.FindAllByCountryCode(this.countryCode)
          .then((response) => {
            if(response && response.data) {
              this.states = response.data;
            }
          })
          .catch(() => {
            this.showmsg({text: 'Ocorreu um erro ao tentar carregar os Estados.', type: 'error'});
            this.loading(false);
          });
    },
    async loadAddressByCpf() {
      if(this.attendeeSelected && this.attendeeSelected.cpf) {
        await this.addressService.FindActiveAddressByCpf(this.$util.removerPontuacaoCPF(this.attendeeSelected.cpf))
            .then(result => {
              if(result && result.data) {
                const addressSaved = result.data;
                this.local.cep = addressSaved.addressZip;
                this.local.address = addressSaved.address;
                this.local.neighborhood = addressSaved.addressNeighborhood;
                this.local.number = addressSaved.addressNumber;
                this.local.city = addressSaved.addressCity;
                this.local.country = addressSaved.addressCountry;

                let stateFound = null;
                if(this.states.length) {
                  stateFound = this.states.find(state => state.uf === addressSaved.addressState);
                }

                this.local.state = addressSaved.addressState ? (stateFound ? stateFound.name : null) : null;

                this.localTemp = Object.assign({}, this.local);
                this.showDetailFields = true;
              }
            })
            .catch(() => {
              this.loading(false);
            });
      }
    },
    async createAndUpdateAddress() {
      if(JSON.stringify(this.local) !== JSON.stringify(this.localTemp)) {
        let stateFound = null;
        if(this.states.length) {
          stateFound = this.states.find(state => state.name === this.local.state);
        }

        const physicalPersonId = this.$auth.user().idPhysicalPerson;

        const addressData = {
          physicalPersonId,
          addressZip: this.local.cep,
          address: this.local.address,
          addressNeighborhood: this.local.neighborhood,
          addressNumber: this.local.number ? this.local.number.toString() : null,
          addressCity: this.local.city,
          addressCountry: this.local.country,
          addressState: stateFound ? stateFound.uf : null,
        };

        await this.addressService.CreateAndUpdateAddress(addressData)
            .then()
            .catch(() => {
              this.showmsg({text: 'Ocorreu um erro ao tentar salvar os dados de endereço.', type: 'error'});
              this.loading(false);
            });
      }
    },
    loadBeneficiaryData() {
      if(this.$auth.user() && this.$auth.user().beneficiaries && this.existsBenefitTelemedicine(this.$auth.user().beneficiaries)) {
        this.loadAttendeeNamesByBeneficiary();
      } else if (this.$auth.user() && this.$auth.user().dependents && this.existsBenefitTelemedicine(this.$auth.user().dependents)) {
        this.loadAttendeeNamesByDependent();
      } else {
        this.stepCount = 0;
      }
    },
    loadAttendeeNamesByBeneficiary() {
      const beneficiary = this.$auth.user().beneficiaries.find(contractedPlan => contractedPlan.benefitName === this.title);
      let attendee = {
        name: beneficiary.personName ? `${beneficiary.personName} (Titular)` : null,
        cpf: beneficiary.personCPF ? this.$util.formatCPF(beneficiary.personCPF) : null,
        biologicGender: beneficiary.personGender ? beneficiary.personGender.toLowerCase() : null,
        gender: null,
        birthDate: beneficiary.personBirthDate ? moment(beneficiary.personBirthDate).format('DD/MM/YYYY') : null,
        phone: beneficiary.personCellPhoneDDD && beneficiary.personCellPhoneNumber ? this.$util.formatPhone((beneficiary.personCellPhoneDDD + beneficiary.personCellPhoneNumber))
            : (beneficiary.personCellPhoneDDD ? beneficiary.personCellPhoneDDD : (beneficiary.personCellPhoneNumber ? beneficiary.personCellPhoneNumber : '')),
        email: beneficiary.personEmail ? beneficiary.personEmail : null,
        userId: beneficiary.userId,
        holderCpf: beneficiary.personCPF ? this.$util.formatCPF(beneficiary.personCPF) : null
      }

      this.attendees.push(attendee);

      if(beneficiary && beneficiary.dependents.length) {

        const dependent = beneficiary.dependents.find(contractedPlan => contractedPlan.benefitName === this.title);
        if(dependent) {
          beneficiary.dependents.forEach(dependent => {
            if(this.attendees.every(attendee => attendee.cpf !== dependent.personCPF) && this.$util.isDependentGreaterThanAgeLimit(dependent.personBirthDate)) {
              attendee = {
                name: dependent.personName ? `${dependent.personName} (Dependente)` : null,
                cpf: dependent.personCPF ? this.$util.formatCPF(dependent.personCPF) : null,
                biologicGender: dependent.personGender ? dependent.personGender.toLowerCase() : null,
                gender: null,
                birthDate: dependent.personBirthDate ? moment(dependent.personBirthDate).format('DD/MM/YYYY') : null,
                phone: dependent.personCellPhoneDDD && dependent.personCellPhoneNumber ? this.$util.formatPhone((dependent.personCellPhoneDDD + dependent.personCellPhoneNumber))
                    : (dependent.personCellPhoneDDD ? dependent.personCellPhoneDDD : (dependent.personCellPhoneNumber ? dependent.personCellPhoneNumber : '')),
                email: dependent.personEmail ? dependent.personEmail : null,
                userId: dependent.userId,
                dependentId: dependent.dependentId,
                holderCpf: beneficiary.personCPF ? this.$util.formatCPF(beneficiary.personCPF) : null
              }
              this.attendees.push(attendee);
            }
          });
        }
      }
    },
    loadAttendeeNamesByDependent() {
      const dependent = this.$auth.user().dependents.find(contractedPlan => contractedPlan.benefitName === this.title);

      let attendee = {
        name: dependent.personName ? `${dependent.personName} (Dependente)` : null,
        cpf: dependent.personCPF ? this.$util.formatCPF(dependent.personCPF) : null,
        biologicGender: dependent.personGender ? dependent.personGender.toLowerCase() : null,
        gender: null,
        birthDate: dependent.personBirthDate ? moment(dependent.personBirthDate).format('DD/MM/YYYY') : null,
        phone: dependent.personCellPhoneDDD && dependent.personCellPhoneNumber ? this.$util.formatPhone((dependent.personCellPhoneDDD + dependent.personCellPhoneNumber))
            : (dependent.personCellPhoneDDD ? dependent.personCellPhoneDDD : (dependent.personCellPhoneNumber ? dependent.personCellPhoneNumber : '')),
        email: dependent.personEmail ? dependent.personEmail : null,
        userId: dependent.userId,
        dependentId: dependent.dependentId,
        holderCpf: dependent.holderCPF
      }

      this.attendees.push(attendee);
    },
    existsBenefitTelemedicine(contractedPlans) {
      return contractedPlans.length && contractedPlans.some(contractedPlan => contractedPlan.benefitName === this.title);
    },
    upateAddress(data) {
      const { code, neighborhood, region, city, log, number, country } = data;

      this.local.cep = code;
      this.local.neighborhood = neighborhood;
      this.local.address = log;
      this.local.city = city;
      this.local.state = region;
      this.local.number = number;
      this.local.country = country;

      this.showDetailFields = true;
      setTimeout(() => {
        this.$refs.formAddressData.validate();
      }, 250);
    },
    async createURLTelemedicine() {
      const holderCPF = this.$util.removerPontuacaoCPF(this.attendeeSelected.holderCpf);
      const patientCPF = this.$util.removerPontuacaoCPF(this.attendeeSelected.cpf);

      await this.appointmentEventService.createURLTelemedicineDuty(holderCPF, patientCPF, this.attendeeSelected.userId, this.attendeeSelected.dependentId)
          .then((result) => {
            if(result && result.data) {
              this.telemedicineDuty = result.data;
              this.openURLTelemedicine();
            }
          })
          .catch(() => {
            this.showmsg({text: 'Ocorreu um erro ao tentar redirecionar para o plantão.', type: 'error'});
            this.loading(false);
          });
    },
    openURLTelemedicine() {
      if(this.telemedicineDuty) {
        window.open(this.telemedicineDuty.url, '_blank');
      }
    },
    onClickAccept() {
      this.dialog.show = false;
    },
    onClickNotAccept() {
      this.$router.back();
    },
    async onClickFirstStep() {
      if(!this.$refs.formAttendeeData.validate()) {
        this.showRequiredMsg();
        return;
      }

      this.loading(true);
      await this.loadAddressByCpf();
      this.loading(false);
      this.stepCount++;
    },
    async onClickSecondStep() {
      if(!this.$refs.formAddressData.validate()) {
        this.showRequiredMsg();
        return;
      }

      this.loading(true);
      await this.createAndUpdateAddress();
      this.loading(false);
      this.stepCount++;
    },
    async onClickThirdStep() {
      this.loading(true);
      if(!this.telemedicineDuty) {
        await this.createURLTelemedicine();
      } else {
        this.openURLTelemedicine();
      }
      this.loading(false);
    },
    showRequiredMsg() {
      this.showmsg({text: 'Por favor, preencha os campos obrigatórios.', type: 'error'});
    },
  },
  async created() {
    this.genderService = new GenderService();
    this.stateService = new StateService();
    this.addressService = new AddressService();
    this.appointmentEventService = new MedicalAppointmentEventService();
    this.telemedicineService = new TelemedicineService();
    this.loading(true);

    this.loadBeneficiaryData();
    await this.loadURLTerms();
    await this.loadGenders();
    await this.loadStates();

    this.loading(false);
  },
};
</script>
<style scoped>

a {
  font-weight: bold;
  text-decoration: none;
  color: var(--v-primary) !important;
}

h1, h4 {
  color: var(--v-primary) !important;
}

.btn {
  text-transform: capitalize;
  font-weight: bold;
  font-family: Open Sans;
  font-size: 15px;
}

.btn-great {
  font-size: 18px;
}

span.topic {
  color: var(--v-primary) !important;
}

.btn-negative {
  color: var(--v-primary) !important;
}

.bg-glass-effect-lighter {
  height: 100% !important;
}

.stepper-layout {
  box-shadow: none !important;
  background-color: transparent !important;
}

.text-dialog {
  text-align: center;
  color: #666;
}

</style>