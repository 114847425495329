/* eslint-disable */
import HttpService from '../HttpService';
export default class StateService {
  constructor() {
    this._httpService = new HttpService(`contract/state`);
  }

  async FindAllByCountryCode(countryCode) {
    return await this._httpService.get('/findAllByCountryCode', { countryCode });
  }

 };
